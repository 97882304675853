import React, { Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Spinner from './ui.library/Spinner'

// PAGES
// import Home from './pages/Home'
// import Questions from './pages/Questions'
// import Plans from './pages/Plans'
import NoMatch from './pages/404/404.index'

const history = createBrowserHistory()
const Home = React.lazy(() => import('./pages/Home'))
const Plans = React.lazy(() => import('./pages/Plans'))
const Questions = React.lazy(() => import('./pages/Questions'))

const Routes = () => (
    <div className="app-wrapper">
        <Router history={history}>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                            <Suspense fallback={<Spinner centerScreen size={15} />}>
                                <Home history={history} />
                            </Suspense>
                        )
                    }}
                />
                <Route
                    exact
                    path="/pricing"
                    render={() => {
                        return (
                            <Suspense fallback={<Spinner centerScreen size={15} />}>
                                <Plans history={history} />
                            </Suspense>
                        )
                    }}
                />
                <Route
                    exact
                    path="/questions"
                    render={() => {
                        return (
                            <Suspense fallback={<Spinner centerScreen size={15} />}>
                                <Questions history={history} />
                            </Suspense>
                        )
                    }}
                />
                <Route component={NoMatch} />
            </Switch>
        </Router>
    </div>
)

export default Routes
